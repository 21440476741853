import React from "react";
import cx from "./Sidebar.module.scss";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";

import { MdCategory, MdKeyboardArrowDown, MdListAlt } from "react-icons/md";

// --------- Images --------- //
import setup from "../../images/icon-setup.svg";


const SettingsMenu = () => {
  return (
    <>
      <div className={`${cx.moreMenuList}`}>
        <h5 className={`${cx.moreMenuTitle}`}>
          <div className={`${cx.menuIcon}`}>
            <img src={setup} alt="setup"/>
          </div>
          <div className={`${cx.menuName}`}>Settings</div>
        </h5>
        <ul>
          <li>
            <NavLink to="/settings/business-category">
              <div className={`${cx.menuIcon}`}>
                <MdCategory />
              </div>
              <div className={`${cx.menuName}`}>Business Category</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings/service-category">
              <div className={`${cx.menuIcon}`}>
                <MdCategory />
              </div>
              <div className={`${cx.menuName}`}>Suggested Service Category</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings/services">
              <div className={`${cx.menuIcon}`}>
                <MdCategory />
              </div>
              <div className={`${cx.menuName}`}>Suggested Services</div>
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/settings/service-category">
              <div className={`${cx.menuIcon}`}>
                <MdCategory />
              </div>
              <div className={`${cx.menuName}`}>Service Category </div>
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/settings/tag">
              <div className={`${cx.menuIcon}`}>
                <MdListAlt />
              </div>
              <div className={`${cx.menuName}`}>Service Tag</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings/attribute1">
              <div className={`${cx.menuIcon}`}>
                <MdListAlt />
              </div>
              <div className={`${cx.menuName}`}>Attribute1</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings/attribute2">
              <div className={`${cx.menuIcon}`}>
                <MdListAlt />
              </div>
              <div className={`${cx.menuName}`}>Attribute2</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings/attribute3">
              <div className={`${cx.menuIcon}`}>
                <MdListAlt />
              </div>
              <div className={`${cx.menuName}`}>Attribute3</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings/promotion">
              <div className={`${cx.menuIcon}`}>
                <MdListAlt />
              </div>
              <div className={`${cx.menuName}`}>Promotion</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings/helpdocument">
              <div className={`${cx.menuIcon}`}>
                <MdListAlt />
              </div>
              <div className={`${cx.menuName}`}>Help Document</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings/tip">
              <div className={`${cx.menuIcon}`}>
                <MdListAlt />
              </div>
              <div className={`${cx.menuName}`}>Tip of the day</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings/headline">
              <div className={`${cx.menuIcon}`}>
                <MdListAlt />
              </div>
              <div className={`${cx.menuName}`}>Headline</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings/datashow">
              <div className={`${cx.menuIcon}`}>
                <MdListAlt />
              </div>
              <div className={`${cx.menuName}`}>Category Web Pages</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings/role">
              <div className={`${cx.menuIcon}`}>
                <MdListAlt />
              </div>
              <div className={`${cx.menuName}`}>Role</div>
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/settings/amenities">
              <div className={`${cx.menuIcon}`}>
                <MdListAlt />
              </div>
              <div className={`${cx.menuName}`}>Amenities </div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings/keywords">
              <div className={`${cx.menuIcon}`}>
                <MdListAlt />
              </div>
              <div className={`${cx.menuName}`}>Keywords</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings/keywords-group">
              <div className={`${cx.menuIcon}`}>
                <MdListAlt />
              </div>
              <div className={`${cx.menuName}`}>Keywords Group</div>
            </NavLink>
          </li>
          
          <li>
            <NavLink to="/settings/tag-type">
              <div className={`${cx.menuIcon}`}>
                <MdListAlt />
              </div>
              <div className={`${cx.menuName}`}>Tag Type</div>
            </NavLink>
          </li> */}
          {/* <li>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <NavLink to="#">
                    <div className={`${cx.menuIcon}`}>
                      <img src={accounts} />
                    </div>
                    <div className={`${cx.menuName}`}>Classifiication</div>
                  </NavLink>
                  <MdKeyboardArrowDown className={`${cx.arrow}`} />
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      <NavLink to="/settings/classifiication">
                        <div className={`${cx.menuIcon}`}>
                          <img src={formate} />
                        </div>
                        <div className={`${cx.menuName}`}>
                          Classifiication 1{" "}
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </li>
          <li>
            <NavLink to="/settings/channel">
              <div className={`${cx.menuIcon}`}>
                <MdListAlt />
              </div>
              <div className={`${cx.menuName}`}>Channel</div>
            </NavLink>
          </li> */}
        </ul>
      </div>
    </>
  );
};

export default SettingsMenu;
