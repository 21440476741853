import React, { useCallback, useEffect, useState } from "react";
import st from "../../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import cx from "./BranchAndLocation.module.scss";
import tb from "../../../datatable.module.scss";
import { Card, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { AiFillDelete, AiOutlinePlusCircle } from "react-icons/ai";
import { FiUpload } from "react-icons/fi";

// --------- Images --------- //
import DemoInfo from "../../../components/DemoInfo/DemoInfo";

import SettingsMenu from "../../../components/Sidebar/SettingsMenu";
import TableDataGrid from "./DataGrid";
import DeletePopup from "../../../components/Modals/DeletePopup";
import AmenitiesM from "../../../components/Modals/Amenities";
import AddTag from "../../../components/Modals/AddTag";
import { httpRequest } from "../../../Apis/commonApis";
import SuccessPasswordModal from "../../../components/Modals/SuccessPasswordModal";

export default function TipOfTheDay() {
  const [tipList, setTipList] = useState<any[]>([])
  useEffect(() => {
    getTip()
  }, [])
  const getTip = async () => {
    let res = await httpRequest(`getTipOfTheDay?type=list`, "get", null, "auth");
    if (res?.status) {
      setTipList(res.data)
    }
  }

  return (
    <>
      <section className={`${st.pageWrapper} ${st.pageWrapperOpen}`}>
        <SettingsMenu />
        <div className={`${st.pageTitle}`}>
          <Row>
            <Col md={6} lg={6}>
              <div className={`${st.titleInfo}`}>
                <div className={`${st.titleInfoBody}`}>
                  <h5>Settings</h5>
                  <p>Tip</p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={6}>
              <DemoInfo />
            </Col>
          </Row>
        </div>

        <div className={`${st.pageWrapperInside}`}>
          <Card>
            <Card.Body>
              <Row className={`${tb.tableAction}`}>
                <Col lg={12}>
                  <div className="d-flex">
                    <NavLink
                      to="add-tip"
                      className={`btn ${tb.addBtn}`}
                    >
                      <AiOutlinePlusCircle className="me-1" />
                      Add Tip
                    </NavLink>
                    <button className={`btn ${tb.upload}`}>
                      <FiUpload className={`${tb.icon}`} />
                      Upload CSV
                      <input type="file" accept=".xls,.xlsx,.csv" />
                    </button>

                    <button className={`btn ${tb.upload}`}>
                      <AiFillDelete className={`${tb.icon} m-0`} />
                    </button>
                  </div>
                </Col>
              </Row>
              <div className={`${tb.dataTable}`}>
                <TableDataGrid tipList={tipList} getTip={getTip} />
              </div>
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
}
