import React, { useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import cx from "./Sidebar.module.scss";
import { useContext, useState } from "react";
import GlobalContext from "../../store/global-context";

// --------- Images --------- //
import business from "../../images/icon-business.svg";
import overview from "../../images/icon-overview.svg";
import schedule from "../../images/icon-schedule.svg";
import team from "../../images/icon-team.svg";
import student from "../../images/icon-student.svg";
import courses from "../../images/icon-course.svg";
import resources from "../../images/icon-resources.svg";
import gradebook from "../../images/icon-gradebook.svg";
import account from "../../images/icon-account.svg";
import report from "../../images/icon-report.svg";
import ksetup from "../../images/icon-k-setup.svg";
import setup from "../../images/icon-setup.svg";
import { Chat } from "@mui/icons-material";
import { BsChatFill } from "react-icons/bs";

const Sidebar = (props: any) => {
  const param = useLocation();
  const globalCtx = useContext(GlobalContext);
  const [reportsList, setReportsList] = useState(
    param.pathname.includes("reports")
  );
  const [inventoryList, setInventoryList] = useState(
    param.pathname.includes("inventory")
  );
  const [menuList, setMenuList] = useState(param.pathname.includes("menu"));
  const [manageList, setManageList] = useState(
    param.pathname.includes("manage")
  );

  return (
    <>
      <aside
        className={`${cx.sidebarBody} ${globalCtx.showMenu ? cx.show : ""}`}
      >
        <div
          className={`${cx.hideBg}`}
          onClick={() => {
            globalCtx.displayMenu(globalCtx.showMenu);
          }}
        ></div>

        <div className={`${cx.sidebarMain}`}>
          <div className={`${cx.menuList}`}>
            <ul className={`${cx.scroll}`}>
              <li>
                <NavLink className={`${cx.signleMenu}`} to="#">
                  <div className={`${cx.menuIcon}`}>
                    <img src={overview} />
                  </div>
                  <div className={`${cx.menuName}`}>Dashboard</div>
                </NavLink>
              </li>

              <li>
                <NavLink className={`${cx.signleMenu}`} to="/business">
                  <div className={`${cx.menuIcon}`}>
                    <img src={student} />
                  </div>
                  <div className={`${cx.menuName}`}>Business</div>
                </NavLink>
              </li>
              <li>
                <NavLink className={`${cx.signleMenu}`} to="/customer-setup">
                  <div className={`${cx.menuIcon}`}>
                    <img src={team} />
                  </div>
                  <div className={`${cx.menuName}`}>Customers</div>
                </NavLink>
              </li>
              <li>
                <NavLink className={`${cx.signleMenu}`} to="/webpages">
                  <div className={`${cx.menuIcon}`}>
                    <img src={courses} />
                  </div>
                  <div className={`${cx.menuName}`}>Webpages</div>
                </NavLink>
              </li>
              <li>
                <NavLink className={`${cx.signleMenu}`} to="/settings">
                  <div className={`${cx.menuIcon}`}>
                    <img src={setup} />
                  </div>
                  <div className={`${cx.menuName}`}>Settings</div>
                </NavLink>
              </li>
              <li>
                <NavLink className={`${cx.signleMenu}`} to="/chat">
                  <div className={`${cx.menuIcon}`}>
                    <BsChatFill style={{ height: '18px', width: '18px' }} />
                  </div>
                  <div className={`${cx.menuName}`}>Chat</div>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
