import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import tb from "../../../datatable.module.scss";
import { NavLink } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import Checkbox from "../../../components/Forms/Checkbox";
import st from "../../../style.module.scss";

import category1 from "../../../images/Chair.svg";
import Status from "../../../components/Forms/Status";
import AddCategory from "../../../components/Modals/AddCategory";
import DeletePopup from "../../../components/Modals/DeletePopup";
import AddTag from "../../../components/Modals/AddTag";
import { httpRequest } from "../../../Apis/commonApis";

const StatusButton = (props: any) => {
  let { params, getTip } = props;
  const navigate = useNavigate()

  const [showDelete, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  const deleteServiceTag = async () => {
    let res = await httpRequest(`deleteTipOfTheDay?_id=${params?.row?.id}`, "Delete", null, "auth");
    if (res?.status) {
      handleDeleteClose();
      getTip()
    }
  }
  

  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <NavLink
              className={`btn ${tb.edit}`}
              title="Edit"
              to='edit-tip'
              state={{_id: params?.row?.id}}
            >
              <MdEdit />
            </NavLink>
          </li>
          <li>
            <NavLink
              className={`btn ${tb.delete}`}
              title="Delete"
              to="#"
              onClick={handleDeleteShow}
            >
              <AiFillDelete />
            </NavLink>
          </li>
          <li>
            <Status status={params?.row?.isActive} api="updateTipOfTheDay" id={params?.row?.id} apiMethod="json" getLatest={getTip} item={params?.row?.title || ''} />
          </li>
        </ul>
      </div>
      <DeletePopup show={showDelete} deleteServiceTag={deleteServiceTag} handleDeleteClose={handleDeleteClose} />
    </>
  );
};



export default function TableDataGrid(props: any) {
  let { tipList, getTip } = props
  let row: any = tipList?.map((item: any, index: any) => {
    return {
      Sr_No: index + 1,
      id: item?._id,
      title: item?.title,
      // url: item?.url,
      content: item?.content,
      isActive: item?.isActive,
      action: item?.isActive
    }
  })
  const columns = [
    { field: "Sr_No", headerName: "Sr No", flex: 1, minWidth: 60 },

    {
      field: "title",
      headerName: "Title",
      flex: 2,
      minWidth: 200,
    },
    {
      field: "content",
      headerName: "Content",
      flex: 2,
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      minWidth: 280,
      sortable: true, // Enable sorting
      sortComparator: (v1: any, v2: any) => {
        // Custom comparator for sorting the status (isActive)
        if (v1 === v2) return 0;
        return v1 ? -1 : 1; // Active (true) first, Inactive (false) second
      },
      renderCell: (params: any) => <StatusButton params={params} getTip={getTip} />,
    },
  ];

  return (


    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={row}
        autoHeight
        pageSize={100}
        rowHeight={48}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
    // <div style={{ width: "100%" }}>
    //   <DataGrid
    //     columns={columns}
    //     rows={row}
    //     // onRowClick={handleRowClick}
    //     autoHeight
    //     hideFooterPagination={true}
    //     pageSize={100}
    //     rowHeight={48}
    //     headerHeight={48}
    //     checkboxSelection
    //     disableSelectionOnClick
    //     components={{ Toolbar: GridToolbar }}
    //     componentsProps={{
    //         toolbar: {
    //             showQuickFilter: true,
    //             quickFilterProps: { debounceMs: 500 },
    //         },
    //     }}
    //   />
    // </div>
  );
}
